import React, { useState, useEffect } from 'react';

function AdminPanel() {
  const [content, setContent] = useState({
    homeTitle: 'Empowering Your Data Journey',
    homeSubtitle: 'Unlock the potential of your data with tailored engineering solutions.',
    aboutText: 'Mr. Integrate LLC is a leading consulting firm specializing in data engineering and program management solutions.',
    servicesText: 'We offer a range of services including data engineering, program management, and IT consulting.',
    portfolioText: 'Explore some of our successful projects and case studies.',
    testimonialsText: 'Our clients love the work we do for them!',
    careersText: 'Join our team and help shape the future of data engineering.',
    contactText: 'Get in touch with us today to see how we can drive your business forward.',
    homeImage: '/path-to-home-image.jpg',
    aboutImage: '/path-to-about-image.jpg',
    servicesImage: '/path-to-services-image.jpg',
    portfolioImage: '/path-to-portfolio-image.jpg',
    testimonialsImage: '/path-to-testimonials-image.jpg',
    backgroundColor: '#ffffff',
    textColor: '#000000',
  });

  // Load saved content from local storage on mount
  useEffect(() => {
    const savedContent = localStorage.getItem('adminContent');
    if (savedContent) {
      setContent(JSON.parse(savedContent));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContent((prevContent) => ({ ...prevContent, [name]: value }));
  };

  const handleImageChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setContent((prevContent) => ({ ...prevContent, [name]: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    localStorage.setItem('adminContent', JSON.stringify(content));
    alert('Content saved! Refresh the page to see the changes.');
  };

  return (
    <div className="min-h-screen p-8 bg-gray-100">
      <h1 className="text-3xl font-bold mb-8 text-blue-900">Admin Panel</h1>

      <div className="space-y-8">
        {/* Home Page Content */}
        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Home Page</h2>
          <label className="block mb-2 font-medium">Home Title:</label>
          <input
            type="text"
            name="homeTitle"
            value={content.homeTitle}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md mb-4"
          />
          <label className="block mb-2 font-medium">Home Subtitle:</label>
          <textarea
            name="homeSubtitle"
            value={content.homeSubtitle}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md mb-4"
          />
          <label className="block mb-2 font-medium">Home Image:</label>
          <input
            type="file"
            name="homeImage"
            onChange={handleImageChange}
            className="mb-4"
          />
          {content.homeImage && (
            <img src={content.homeImage} alt="Home Preview" className="w-64 h-64 object-cover mb-4 rounded-md" />
          )}
        </section>

        {/* Repeat similar sections for About, Services, Portfolio, Testimonials, Careers, and Contact */}
        {/* Example: About */}
        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">About Page</h2>
          <label className="block mb-2 font-medium">About Text:</label>
          <textarea
            name="aboutText"
            value={content.aboutText}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <label className="block mb-2 font-medium">About Image:</label>
          <input
            type="file"
            name="aboutImage"
            onChange={handleImageChange}
            className="mb-4"
          />
          {content.aboutImage && (
            <img src={content.aboutImage} alt="About Preview" className="w-64 h-64 object-cover mb-4 rounded-md" />
          )}
        </section>

        {/* Style Settings */}
        <section className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Style Settings</h2>
          <label className="block mb-2 font-medium">Background Color:</label>
          <input
            type="color"
            name="backgroundColor"
            value={content.backgroundColor}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md mb-4"
          />
          <label className="block mb-2 font-medium">Text Color:</label>
          <input
            type="color"
            name="textColor"
            value={content.textColor}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md mb-4"
          />
        </section>

        <button
          onClick={handleSave}
          className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default AdminPanel;
