import React from 'react';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <div className="container mx-auto p-8 space-y-16">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-green-600 to-blue-500 text-white p-8 md:p-16 rounded-lg shadow-md text-center">
        <h1 className="text-3xl md:text-5xl font-bold mb-4">Our Services</h1>
        <p className="text-lg md:text-xl max-w-3xl mx-auto">
          Tailored data engineering and program management solutions that drive success.
        </p>
      </section>

      {/* Data Engineering Services */}
      <section className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-blue-900">Data Engineering</h2>
        <p className="mb-4 text-gray-700">
          At Mr. Integrate LLC, we specialize in transforming raw data into actionable insights. Our data engineering
          services are designed to help businesses manage, process, and analyze large datasets with ease.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-gradient-to-r from-green-300 to-blue-300 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-2">Data Pipeline Development</h3>
            <p className="text-gray-600">
              Build robust data pipelines that ensure seamless data flow and enable real-time analytics.
            </p>
          </div>
          <div className="bg-gradient-to-r from-green-300 to-blue-300 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-2">Data Warehousing</h3>
            <p className="text-gray-600">
              Design and implement scalable data warehousing solutions for efficient data storage and retrieval.
            </p>
          </div>
          <div className="bg-gradient-to-r from-green-300 to-blue-300 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-2">Data Integration</h3>
            <p className="text-gray-600">
              Integrate data from multiple sources to create a unified view that powers business decisions.
            </p>
          </div>
        </div>
      </section>

      {/* Program Management Services */}
      <section className="bg-gray-100 p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-blue-900">Program Management</h2>
        <p className="mb-4 text-gray-700">
          Our program management services ensure that your projects are delivered on time, within budget, and aligned
          with your strategic goals. We bring structure and clarity to complex projects, allowing you to focus on what
          matters most—growing your business.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-gradient-to-r from-yellow-300 to-orange-300 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-2">Project Planning</h3>
            <p className="text-gray-600">
              Develop comprehensive project plans that outline objectives, timelines, and deliverables.
            </p>
          </div>
          <div className="bg-gradient-to-r from-yellow-300 to-orange-300 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-2">Risk Management</h3>
            <p className="text-gray-600">
              Identify, assess, and mitigate risks to keep your projects on track and avoid delays.
            </p>
          </div>
          <div className="bg-gradient-to-r from-yellow-300 to-orange-300 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-2">Agile Transformation</h3>
            <p className="text-gray-600">
              Implement Agile practices that foster collaboration, flexibility, and faster time-to-market.
            </p>
          </div>
        </div>
      </section>

      {/* IT Consulting Services */}
      <section className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-blue-900">IT Consulting</h2>
        <p className="mb-4 text-gray-700">
          Leverage our expertise to optimize your IT infrastructure and drive digital transformation. Our IT consulting
          services are designed to help you stay ahead in an ever-changing technological landscape.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-gradient-to-r from-purple-300 to-pink-300 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-2">Cloud Strategy</h3>
            <p className="text-gray-600">
              Develop a cloud strategy that aligns with your business goals and enables scalable growth.
            </p>
          </div>
          <div className="bg-gradient-to-r from-purple-300 to-pink-300 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-2">System Integration</h3>
            <p className="text-gray-600">
              Ensure seamless integration between your existing systems and new technologies.
            </p>
          </div>
          <div className="bg-gradient-to-r from-purple-300 to-pink-300 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-2">Cybersecurity</h3>
            <p className="text-gray-600">
              Protect your business with tailored cybersecurity solutions and risk management.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-gradient-to-r from-blue-900 to-green-600 text-white p-8 md:p-16 rounded-lg shadow-md text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Empower Your Business with Expert Solutions</h2>
        <p className="mb-6 text-lg md:text-xl">
          Ready to take your business to the next level? Our team is here to help you with the right solutions.
        </p>
        <Link to="/contact" className="bg-white text-blue-900 px-6 py-3 rounded-full font-semibold hover:bg-blue-100">
          Get in Touch
        </Link>
      </section>
    </div>
  );
}

export default Services;
