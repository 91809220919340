import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Admin from './Admin';
function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        {/* Header with Navigation */}
        <header className="bg-white shadow-md fixed top-0 left-0 w-full z-10">
          <div className="container mx-auto p-4 flex justify-between items-center">
            <Link to="/" className="flex items-center">
              <img
                src="/images/mrintegrate logo.png" // Replace with your actual logo path
                alt="Mr. Integrate LLC Logo"
                className="h-8 w-8 mr-2"
              />
              <h1 className="text-xl md:text-2xl font-bold text-blue-900">Mr. Integrate LLC</h1>
            </Link>
            <nav className="hidden md:flex space-x-4">
              <Link to="/" className="text-gray-800 hover:text-blue-600">Home</Link>
              <Link to="/about" className="text-gray-800 hover:text-blue-600">About</Link>
              <Link to="/services" className="text-gray-800 hover:text-blue-600">Services</Link>
              <Link to="/contact" className="text-gray-800 hover:text-blue-600">Contact</Link>
            </nav>
            <button 
              className="md:hidden text-gray-800 hover:text-blue-600 focus:outline-none" 
              onClick={toggleMobileMenu}
            >
              <i className={`fa-solid ${isMobileMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
            </button>
          </div>

          {/* Mobile Menu */}
          {isMobileMenuOpen && (
            <nav className="md:hidden bg-white shadow-md">
              <ul className="space-y-2 p-4">
                <li>
                  <Link 
                    to="/" 
                    className="block text-gray-800 hover:text-blue-600" 
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/about" 
                    className="block text-gray-800 hover:text-blue-600" 
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/services" 
                    className="block text-gray-800 hover:text-blue-600" 
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/contact" 
                    className="block text-gray-800 hover:text-blue-600" 
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          )}
        </header>

        {/* Main content with top margin to avoid overlap */}
        <main className="container mx-auto p-6 mt-20">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
	    <Route path="/admin" element={<Admin />} />

          </Routes>
        </main>

        {/* Footer */}
        <footer className="bg-blue-900 text-white text-center p-4 mt-8">
          <p>&copy; 2024 Mr. Integrate LLC. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
