import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

function About() {
  const canvasRef = useRef(null);
  const [score, setScore] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const scale = 20;
    const rows = canvas.height / scale;
    const columns = canvas.width / scale;

    let snake = [
      { x: 10, y: 10 },
      { x: 9, y: 10 },
    ];
    let direction = { x: 1, y: 0 };
    let food = randomFoodPosition();

    function randomFoodPosition() {
      return {
        x: Math.floor(Math.random() * columns),
        y: Math.floor(Math.random() * rows),
      };
    }

    function gameLoop() {
      update();
      draw();
      setTimeout(gameLoop, 100);
    }

    function update() {
      // Move the snake
      const head = { x: snake[0].x + direction.x, y: snake[0].y + direction.y };

      // Check for collisions with walls
      if (head.x < 0 || head.x >= columns || head.y < 0 || head.y >= rows) {
        resetGame();
        return;
      }

      // Check for collisions with itself
      for (let segment of snake) {
        if (segment.x === head.x && segment.y === head.y) {
          resetGame();
          return;
        }
      }

      // Check for eating food
      if (head.x === food.x && head.y === food.y) {
        snake.push({});
        setScore((prev) => prev + 1);
        food = randomFoodPosition();
      } else {
        snake.pop();
      }

      snake.unshift(head);
    }

    function draw() {
      // Clear the canvas
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the snake
      ctx.fillStyle = 'lime';
      for (let segment of snake) {
        ctx.fillRect(segment.x * scale, segment.y * scale, scale, scale);
      }

      // Draw the food
      ctx.fillStyle = 'red';
      ctx.fillRect(food.x * scale, food.y * scale, scale, scale);
    }

    function handleKeyDown(event) {
      switch (event.key) {
        case 'ArrowUp':
          if (direction.y === 0) direction = { x: 0, y: -1 };
          break;
        case 'ArrowDown':
          if (direction.y === 0) direction = { x: 0, y: 1 };
          break;
        case 'ArrowLeft':
          if (direction.x === 0) direction = { x: -1, y: 0 };
          break;
        case 'ArrowRight':
          if (direction.x === 0) direction = { x: 1, y: 0 };
          break;
        default:
          break;
      }
    }

    function resetGame() {
      snake = [
        { x: 10, y: 10 },
        { x: 9, y: 10 },
      ];
      direction = { x: 1, y: 0 };
      setScore(0);
      food = randomFoodPosition();
    }

    window.addEventListener('keydown', handleKeyDown);
    gameLoop();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="container mx-auto p-8 space-y-16">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-purple-800 to-blue-600 text-white p-8 md:p-16 rounded-lg shadow-md text-center">
        <h1 className="text-3xl md:text-5xl font-bold mb-4">About Mr. Integrate LLC</h1>
        <p className="text-lg md:text-xl max-w-3xl mx-auto">
          Your trusted partner for data engineering and program management solutions, helping businesses transform data into actionable insights.
        </p>
      </section>

      {/* Company Overview */}
      <section className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-blue-900">Who We Are</h2>
        <p className="mb-4 text-gray-700">
          Mr. Integrate LLC is a leading consulting firm specializing in data engineering and program management solutions. With over 15 years of industry experience, we are committed to delivering innovative solutions tailored to meet the unique needs of our clients.
        </p>
        <p className="text-gray-700">
          Our team of experts is passionate about helping businesses unlock the full potential of their data and streamline their operations.
        </p>
      </section>

      {/* Snake Game Section */}
      <section className="bg-gray-100 p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-6 text-blue-900 text-center">Take a Break with Snake!</h2>
        <div className="flex justify-center">
          <canvas
            ref={canvasRef}
            width={400}
            height={400}
            className="bg-black rounded-lg shadow-md"
          ></canvas>
        </div>
        <p className="text-center text-gray-600 mt-4">Score: {score}</p>
      </section>

      {/* Call to Action */}
      <section className="bg-gradient-to-r from-blue-900 to-purple-600 text-white p-8 md:p-16 rounded-lg shadow-md text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Partner with Us for Success</h2>
        <p className="mb-6 text-lg md:text-xl">
          Ready to transform your business? Let's work together to create data-driven solutions that deliver real results.
        </p>
        <Link to="/contact" className="bg-white text-blue-900 px-6 py-3 rounded-full font-semibold hover:bg-blue-100">
          Contact Us Today
        </Link>
      </section>
    </div>
  );
}

export default About;
