import React, { useState } from 'react';
import axios from 'axios';

// Get the API base URL from environment variables, fallback to relative path for production
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || '';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setResponseMessage('');
    setIsError(false);

    try {
      // Use the base URL from environment variables for API requests
      const response = await axios.post(`${API_BASE_URL}/api/contact`, formData);
      setResponseMessage('Thank you for reaching out! We will get back to you soon.');
    } catch (error) {
      setResponseMessage('An error occurred. Please try again later.');
      setIsError(true);
      console.error('Error sending contact form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto p-8 space-y-16">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-blue-600 to-green-500 text-white p-8 md:p-16 rounded-lg shadow-md text-center">
        <h1 className="text-3xl md:text-5xl font-bold mb-4">Get in Touch</h1>
        <p className="text-lg md:text-xl max-w-3xl mx-auto">
          We're here to help you with all your data engineering and program management needs. Reach out to us today!
        </p>
      </section>

      {/* Contact Form */}
      <section className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-6 text-blue-900 text-center">Contact Us</h2>
        <form onSubmit={handleSubmit} className="space-y-6 max-w-2xl mx-auto">
          <div>
            <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
              Full Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your full name"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email address"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="5"
              placeholder="Write your message here"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white font-semibold py-3 rounded-lg hover:bg-blue-700 transition-colors"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
          {responseMessage && (
            <p
              className={`text-center mt-4 ${
                isError ? 'text-red-600' : 'text-blue-900'
              }`}
            >
              {responseMessage}
            </p>
          )}
        </form>
      </section>

      {/* Company Details and Map */}
      <section className="bg-gray-100 p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-6 text-blue-900 text-center">Our Office</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Contact Details */}
          <div className="flex flex-col space-y-4">
            <div>
              <h3 className="text-xl font-semibold text-blue-900">Address</h3>
              <p className="text-gray-700">
                10021 Gramercy Dr, Oklahoma City, OK 73139, United States
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-900">Phone</h3>
              <p className="text-gray-700">+1 (904) 735-6075</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-900">Email</h3>
              <p className="text-gray-700">nsmason1@gmail.com</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-900">Business Hours</h3>
              <p className="text-gray-700">Monday - Friday: 9:00 AM - 5:00 PM</p>
              <p className="text-gray-700">Saturday - Sunday: Closed</p>
            </div>
          </div>

          {/* Map Placeholder */}
          <div className="flex items-center justify-center">
            <div className="w-full h-64 bg-gray-300 rounded-lg shadow-inner flex items-center justify-center">
              <p className="text-gray-700">Map Placeholder</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-gradient-to-r from-green-600 to-blue-500 text-white p-8 md:p-16 rounded-lg shadow-md text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Let's Work Together</h2>
        <p className="mb-6 text-lg md:text-xl">
          Ready to discuss your next project? Contact us today and let's create something amazing together.
        </p>
        <a href="mailto:nate@mrintegrate.com" className="bg-white text-blue-900 px-6 py-3 rounded-full font-semibold hover:bg-blue-100">
          Email Us
        </a>
      </section>
    </div>
  );
}

export default Contact;
