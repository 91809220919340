import React from 'react';
import $ from 'jquery';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Home() {
  // Slider settings for carousels
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
    arrows: false,
  };

  return (
    <div className="space-y-16">
      {/* Hero Section */}
      <section className="relative h-[300px] md:h-[400px]">
        <Slider {...sliderSettings} className="w-full h-full">
          {/* Slide 1 */}
          <div className="relative h-full">
            <img
              src="/images/office.webp"
              alt="Modern office space"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
              <div className="text-center p-6 rounded-md max-w-xl mx-auto">
                <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white">Empowering Your Data Journey</h1>
                <p className="text-lg mb-6 text-white">
                  Unlock the potential of your data with tailored engineering solutions.
                </p>
                <Link
                  to="/services"
                  className="bg-white text-blue-900 px-6 py-3 rounded-full font-semibold hover:bg-blue-100"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>

          {/* Slide 2 */}
          <div className="relative h-full">
            <img
              src="/images/city.webp"
              alt="City skyline"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
              <div className="text-center p-6 rounded-md max-w-xl mx-auto">
                <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white">Vision and Growth</h1>
                <p className="text-lg mb-6 text-white">
                  Looking forward to the future of data-driven success with you.
                </p>
                <Link
                  to="/contact"
                  className="bg-white text-blue-900 px-6 py-3 rounded-full font-semibold hover:bg-blue-100"
                >
                  Start Your Journey
                </Link>
              </div>
            </div>
          </div>

          {/* Slide 3 */}
          <div className="relative h-full">
            <img
              src="/images/servers.webp"
              alt="State-of-the-art servers"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
              <div className="text-center p-6 rounded-md max-w-xl mx-auto">
                <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white">State-of-the-Art Data Centers</h1>
                <p className="text-lg mb-6 text-white">
                  Our infrastructure supports your business with unmatched reliability.
                </p>
                <Link
                  to="/services"
                  className="bg-white text-blue-900 px-6 py-3 rounded-full font-semibold hover:bg-blue-100"
                >
                  Explore Solutions
                </Link>
              </div>
            </div>
          </div>

          {/* Slide 4 */}
          <div className="relative h-full">
            <img
              src="/images/people.webp"
              alt="Professional team"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
              <div className="text-center p-6 rounded-md max-w-xl mx-auto">
                <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white">Collaboration and Success</h1>
                <p className="text-lg mb-6 text-white">
                  Our team works closely with yours to ensure seamless project delivery.
                </p>
                <Link
                  to="/contact"
                  className="bg-white text-blue-900 px-6 py-3 rounded-full font-semibold hover:bg-blue-100"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>

          {/* Slide 5 */}
          <div className="relative h-full">
            <img
              src="/images/groovy.webp"
              alt="Groovy work environment"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
              <div className="text-center p-6 rounded-md max-w-xl mx-auto">
                <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white">A Groovy Work Environment</h1>
                <p className="text-lg mb-6 text-white">
                  Foster creativity and productivity in a relaxed yet professional space.
                </p>
                <Link
                  to="/services"
                  className="bg-white text-blue-900 px-6 py-3 rounded-full font-semibold hover:bg-blue-100"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      {/* Overview of Services */}
      <section className="container mx-auto p-8">
        <h2 className="text-3xl font-bold text-center mb-8 text-blue-900">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-gradient-to-r from-green-300 to-blue-300 rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-4">Data Engineering</h3>
            <p className="mb-4">Transform raw data into actionable insights with scalable solutions.</p>
            <Link to="/services" className="text-blue-900 hover:underline">
              Read More &rarr;
            </Link>
          </div>
          <div className="bg-gradient-to-r from-yellow-300 to-orange-300 rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-4">Program Management</h3>
            <p className="mb-4">Ensure timely delivery and alignment of your projects with our expert PM services.</p>
            <Link to="/services" className="text-blue-900 hover:underline">
              Read More &rarr;
            </Link>
          </div>
          <div className="bg-gradient-to-r from-purple-300 to-pink-300 rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-4">IT Consulting</h3>
            <p className="mb-4">Leverage our experience to optimize your IT infrastructure and strategy.</p>
            <Link to="/services" className="text-blue-900 hover:underline">
              Read More &rarr;
            </Link>
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="bg-indigo-100 py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6 text-indigo-900">Why Choose Mr. Integrate LLC?</h2>
          <p className="max-w-3xl mx-auto mb-8 text-indigo-700">
            Our approach combines technical expertise with a deep understanding of your business needs. We are
            dedicated to delivering solutions that provide tangible results and long-term success.
          </p>
          <div className="flex flex-wrap justify-center gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md w-60">
              <i className="fa-solid fa-chart-line text-blue-900 text-3xl mb-4"></i>
              <h3 className="text-xl font-semibold">Proven Results</h3>
              <p>Over 15 years of success in driving efficiency and growth for clients.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md w-60">
              <i className="fa-solid fa-lightbulb text-blue-900 text-3xl mb-4"></i>
              <h3 className="text-xl font-semibold">Innovative Solutions</h3>
              <p>We stay ahead of trends to provide the latest technologies.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md w-60">
              <i className="fa-solid fa-user-check text-blue-900 text-3xl mb-4"></i>
              <h3 className="text-xl font-semibold">Client-Centric</h3>
              <p>Your needs are our priority, and we tailor our approach to fit your goals.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="container mx-auto py-16 text-center bg-gradient-to-r from-blue-500 to-indigo-500 text-white">
        <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Business?</h2>
        <p className="mb-6">
          Get in touch with us today to see how we can drive your business forward with cutting-edge solutions.
        </p>
        <Link to="/contact" className="bg-white text-blue-900 px-6 py-3 rounded-full font-semibold hover:bg-blue-100">
          Contact Us
        </Link>
      </section>
    </div>
  );
}

export default Home;